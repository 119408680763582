<template>
   <UiListCard
    :item="project"
    :selectable="false"
    :selected="project.checked"
    :class="[`project mb-3 relative ${size}`]"
    :to="go(project)"
  >
    <template #badges>
      <UiBadge color="primary" v-if="project.is_new">New</UiBadge>
      <UiBadge color="in_review" v-if="project.review_needed" @click.stop :to="buildReviewRevisionLink(project, 'item_for_review')">Review Needed</UiBadge>
      <UiBadge color="revision_needed" v-if="project.revision_needed" @click.stop :to="buildReviewRevisionLink(project, 'item_for_revision')">Revision needed</UiBadge>
    </template>
    <template v-slot:image>
      <UiSelectedIndicator
        class="absolute mt-2 ml-2"
        :selected="project.checked"
        @click.prevent="updateProject({id: project.id, checked: !project.checked})"
      />
      <img v-if="project.file" :src="getFileThumbnails(project.file.thumbnails) || project.file.url" alt=""/>
      <UIImage v-else name-path="default-img.png"/>
    </template>

    <template v-slot:body>
      <div class="project-section-wrap d-flex align-center">
        <div class="project-section px-5 mr-auto">
          <div v-if="size === 'medium'" class="d-grid grid-cols-3auto gap-2 align-center mb-3 text-captions-1">
            <v-tooltip top color="accent">
              <template v-slot:activator="{ on, attrs }">
                <span class="text-body flex-auto font-weight-semi-bold gray-100--text text-truncate" v-bind="attrs"
                      v-on="on">{{ project.title }}</span>
              </template>
              <span class="white--text font-weight-medium ">{{ project.title }}</span>
            </v-tooltip>
            <div class="d-flex flex-shrink-0 align-center flex-auto text-no-wrap">
              <IconDoubleChevronRight width="8" class="mr-2 gray-30--text flex-shrink-0"/>
              <span class="gray-60--text">Updated: {{ timeDistance(project.updated_at) }}</span>
            </div>

            <div class="flex-auto">
              <IconDoubleChevronRight width="8" class="mr-2 gray-30--text"/>
              <ModuleStyleDueDate v-if="!isProjectCompleted && project?.due_date" :project="project" @updateData="updateProject($event)">
                <template v-slot:activator="{attrs, on, dueDaysLeft }">
                  <v-btn
                    class="due-date-btn"
                    :color="dueDaysLeft.backgroundColor"
                    v-bind="attrs"
                    v-on="on"
                    :elevation="0"
                    @click.prevent
                  >
                    <span class="text-captions-2" :class="[dueDaysLeft.textColor]">{{ dueDaysLeft.prefix }}</span> <span class="dark text-captions-2 font-weight-bold ml-1" :class="[dueDaysLeft.textColor]">
                  {{ dueDaysLeft.value }} {{dueDaysLeft.suffix }}
                </span>
                  </v-btn>
                </template>
              </ModuleStyleDueDate>
            </div>
          </div>
          <div class="d-grid grid-cols-3auto align-center gap-2" v-if="size === 'medium'">
            <v-tooltip top color="accent">
              <template v-slot:activator="{ on, attrs }">
                <div class="text-captions-1 text-truncate" v-on="on" v-bind="attrs">
                  By:
                  <UiAvatar :text="project.user.first_name" size="20" class="ml-1"/>
                  {{ projectOwner(project) }}
                </div>
              </template>
              <span class="white--text font-weight-medium ">{{ projectOwner(project) }}</span>
            </v-tooltip>
            <div class="ml-4 text-captions-1 gray-60--text">
              Created: <strong class="font-weight-semi-bold">{{
                format(new Date(project.created_at), 'dd/MM/yyyy')
              }}</strong>
            </div>
          </div>
          <div class="text-captions-1 text-truncate" v-else>
            By:
            <UiAvatar :text="project.user.first_name" size="20" class="ml-1"/>
            {{ projectOwner(project) }}
          </div>

        </div>

        <div v-if="size === 'small'" class="project-section px-6 mr-auto border-left">
          <div v-if="project.active_module" class="d-flex align-center">
            <div class="d-flex align-center mr-auto overflow-hidden">
              <UiProjectModuleIcon width="12" :icon="project.active_module.icon"
                                    class="mr-1 flex-shrink-0 gray-60--text"/>
              <span class="text-captions-1 text-no-wrap overflow-hidden text-overflow-ellipsis">{{
                  project.active_module.title
                }}</span>
            </div>
            <UiProjectStatus block :id="project.active_module.status" statusType="module" class="flex-shrink-0 ml-2"
                              style="width: 72px"/>
          </div>
        </div>

        <div class="project-section px-6 mr-auto border-left">
          <div class="d-flex align-center">
            <div v-if="project.active_module && size === 'medium'" style="width: 90px">
              <div class="d-flex align-center mb-2 overflow-hidden">
                <UiProjectModuleIcon width="12" :icon="project.active_module.icon"
                                      class="mr-1 flex-shrink-0 gray-60--text"/>
                <span class="text-captions-1 text-no-wrap overflow-hidden text-overflow-ellipsis">{{
                    project.active_module.title
                  }}</span>
              </div>
              <UiProjectStatus block :id="project.active_module.status" statusType="module"/>
            </div>

            <div v-if="size === 'small'" class="text-captions-1">
              <div class="text-captions-1 gray-60--text">
                Created: <strong class="font-weight-semi-bold">{{
                  format(new Date(project.created_at), 'dd/MM/yyyy')
                }}</strong>
              </div>
              <p class="mb-0 gray-60--text">Updated: {{ timeDistance(project.updated_at) }}</p>
              <ModuleStyleDueDate v-if="!isProjectCompleted && project?.due_date" :project="project" @updateData="updateProject($event)">
                <template v-slot:activator="{attrs, on, dueDaysLeft }">
                  <v-btn
                    class="due-date-btn"
                    :color="dueDaysLeft.backgroundColor"
                    v-bind="attrs"
                    v-on="on"
                    :elevation="0"
                    @click.prevent
                  >
                    <span class="text-captions-2" :class="[dueDaysLeft.textColor]">{{ dueDaysLeft.prefix }}</span> <span class="dark text-captions-2 font-weight-bold ml-1" :class="[dueDaysLeft.textColor]">
                  {{ dueDaysLeft.value }} {{dueDaysLeft.suffix }}
                </span>
                  </v-btn>
                </template>
              </ModuleStyleDueDate>
            </div>

            <div class="d-flex align-center ml-auto">

                <UiBtn color="gray-60" icon @click.prevent="goToComment(project.last_comment_notification_data)" class="mr-2" :disabled="!project.last_comment_notification_data">
                  <v-badge
                    color="accent" dot overlap bordered :value="!!project.last_comment_notification_data"
                  >
                    <IconChat width="16" class="mt-1"/>
                  </v-badge>
                </UiBtn>

              <ProjectMenu :project="project"/>

              <UiBtn color="gray-60" icon class="ml-8"
                      @click.prevent="updateProject({id: project.id, showModulesTable: !project.showModulesTable})">
                <IconCaretDown width="16" :class="{'icon-transition rotate-180': project.showModulesTable}"/>
              </UiBtn>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div v-if="project.loading" class="absolute inset-0 d-flex justify-center align-center gray-30"
            style="z-index: 2;" @click.prevent.self>
        <v-progress-circular
          :size="48"
          color="accent"
          indeterminate
        />
      </div>
      <v-expand-transition>
        <v-simple-table
          v-if="project.showModulesTable"
          dense
          class="project-modules-table rounded-0"
        >
          <template v-slot:default>
            <thead>
            <tr>
              <th class="px-3 text-captions-1 text-center white--text" style="width: 418px;">Process</th>
              <th class="px-3 text-captions-1 text-center white--text" style="width: 150px;">Status</th>
              <th class="px-3 text-captions-1 text-center white--text" style="width: 150px;">Due Date</th>
              <th class="px-3 text-captions-1 text-center white--text" style="width: 200px;"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="module in projectModules(project)" :key="module.id">
              <td>
                <div class="d-flex align-center mr-auto overflow-hidden" style="max-width: 410px">
                  <UiProjectModuleIcon width="12" :icon="module.icon" class="mr-1 flex-shrink-0 gray-60--text"/>
                  <span class="text-captions-1 text-no-wrap overflow-hidden text-overflow-ellipsis">{{
                      module.title
                    }}</span>
                </div>
              </td>
              <td>
                <UiProjectStatus block :id="module.status" statusType="module"/>
              </td>
              <td class="font-weight-semi-bold text-captions-1 text-center">
                <div
                  v-if="module.status !== $config.project.moduleStatus.finished"
                  class="due-date-tag mx-auto"
                  :class="[module.dueDaysLeftData.backgroundColor]"
                >
                  <span class="text-captions-2" :class="[module.dueDaysLeftData.textColor]">{{ module.dueDaysLeftData.prefix }}</span> <span class="dark text-captions-2 font-weight-bold ml-1" :class="[module.dueDaysLeftData.textColor]">
                  {{ module.dueDaysLeftData.value }} {{module.dueDaysLeftData.suffix }}
                  </span>
                </div>
              </td>
              <td class="px-7">
                <div class="d-flex justify-end">
                  <UiBtn color="gray-60" icon disabled class="mr-2">
                    <IconDownload width="16"/>
                  </UiBtn>

                  <UiBtn color="gray-60" icon :to="go(project, module)" @click.prevent>
                    <IconEye width="16"/>
                  </UiBtn>
                </div>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-expand-transition>
    </template>
  </UiListCard>
</template>

<script>
import {mapGetters} from "vuex";
import UiListCard from "@/components/UI/UiListCard";
import UIImage from "@/components/UI/UIImage";
import UiBtn from "@/components/UI/UiBtn";
import UiProjectStatus from "@/components/UI/UiProjectStatus";
import UiSelectedIndicator from "@/components/UI/UiSelectedIndicator";
import UiAvatar from "@/components/UI/UiAvatar";
import UiProjectModuleIcon from "@/components/UI/UiProjectModuleIcon";
import ProjectMenu from "@/views/dashboard/ProjectMenu";
import {differenceInDays, format, formatDistanceToNow} from "date-fns";
import {buildReviewRevisionLink, getDueDaysLeftData, getFileThumbnails} from "@/utils/helpers";
import UiBadge from "@/components/UI/UiBadge.vue";
import ModuleStyleDueDate from "@/components/project/ModuleStyleDueDate.vue";

export default {
  name: 'ProjectListCard',
  components: {
    ModuleStyleDueDate,
    UiBadge,
    IconDoubleChevronRight: () => import('@/components/icons/IconDoubleChevronRight'),
    IconChat: () => import('@/components/icons/IconChat'),
    IconCaretDown: () => import('@/components/icons/IconCaretDown'),
    IconDownload: () => import('@/components/icons/IconDownload'),
    IconEye: () => import('@/components/icons/IconEye'),

    UiListCard,
    UIImage,
    UiBtn,
    UiSelectedIndicator,
    UiProjectStatus,
    UiAvatar,
    UiProjectModuleIcon,
    ProjectMenu,
  },
  props: {
    size: {
      type: String,
      default: 'medium',
    },
    project: {
      type: Object,
      default: () => {}
    },
  },
  computed: {
    ...mapGetters([
      'getProjectsList',
    ]),
    projectModules() {
      return (project) => {
        const modules = project.modules.filter(module => module.type !== this.$config.project.moduleTypes.moodBoard).map(module => {
          return {
            ...module,
            dueDaysLeftData: getDueDaysLeftData(module.due_date)
          }
        });
        modules.sort((a, b) => {
          if (a.workflow_type !== b.workflow_type) {
            return a.workflow_type - b.workflow_type;
          }
          return a.sort_order - b.sort_order;
        });
        return modules
      }
    },
    isProjectCompleted() {
      return this.project.status === this.$config.project.status.approved
    },
  },
  methods: {
    buildReviewRevisionLink,
    format,
    getFileThumbnails,
    projectOwner(project) {
      let lastName = project.user.last_name ? `${project.user.last_name.split('')[0]}.` : '';
      return `${project.user.first_name} ${lastName}`;
    },
    timeDistance(date) {
      const now = new Date()
      if (differenceInDays(new Date(date), now) < 0) {
        return format(new Date(date), 'dd/MM/yyyy');
      } else {
        const res = formatDistanceToNow(new Date(date), {addSuffix: true});
        return res.replace('about ', '');
      }
    },
    // dueDaysLeft(date) {
    //   const now = new Date();
    //   const dueData = new Date(date);
    //   return differenceInDays(dueData, now);
    // },
    go(project, module = null) {
      if (!module) {
        if (project.active_module) {
          if (project.active_module.type === this.$config.project.moduleTypes.moodBoard) {
            return {name: 'MoodBoard', params: {project_id: project.id}};
          } else if (project.active_module.type === this.$config.project.moduleTypes.design) {
            return {
              name: 'ProjectDesign',
              params: {project_id: project.id},
              query: {module: project.active_module.id}
            };
          }
        } else {
          return {name: 'ProjectEdit', params: {project_id: project.id}};
        }
      } else if (module.type === this.$config.project.moduleTypes.moodBoard) {
        return {name: 'MoodBoard', params: {project_id: project.id}};
      } else if (module.type === this.$config.project.moduleTypes.design) {
        return {name: 'ProjectDesign', params: {project_id: project.id}, query: {module: module.id}};
      }
    },
    updateProject(data) {
      if(this.getProjectsList.find(el => el.is_folder && el.checked)) {
        return
      }
      this.$store.dispatch('updateDashboardProject', data);
    },
    goToComment(data) {
      this.$store.state.ProjectDesign.activeDesignMode = this.$config.project.designModes.task;

      this.$router.replace({
        name: 'DesignViewPage',
        params: {project_id: data.project_id},
        query: {
          module_id: data.module_id,
          style_id: data.style_id,
          file_id: data.file_id,
          comment_id: data.comment_id,
          task_id: data?.task_id || undefined,
          view_mode: this.$config.project.designModes.task,
        }
      });
    },
  }
}
</script>

<style scoped lang="scss">
.project {
  ::v-deep .ui-list-card__image {
    padding-bottom: 0;
  }

  .project-section-wrap {
    width: 100%;
    height: 100%;
  }

  .project-section {
    width: 40%;

    &:first-child {
      width: 60%;
    }

    .v-btn.v-btn--disabled {
      opacity: .57;
    }
  }

  &.small {
    //::v-deep .ui-list-card__image {
    //  width: 86px;
    //}

    .project-section {
      width: 32%;

      &:first-child {
        width: 23%;
      }

      &:last-child {
        width: 45%;
      }
    }
  }

  &.is-selected .project-modules-table {
    border-top-color: var(--v-accent-base);
  }

  ::v-deep .project-modules-table {
    border-top: 1px solid var(--v-gray-30-base);

    thead th {
      height: 24px;
      background-color: var(--v-gray-30-base);
      border-bottom: none !important;

      &:not(:last-child) {
        border-right: 1px solid var(--v-gray-0-base);
      }
    }

    tbody td {
      border-color: var(--v-gray-10-base) !important;

      &:not(:last-child) {
        border-right: 1px solid var(--v-gray-10-base);
      }
    }

  }
}

.border-left::before {
  content: '';
  position: absolute;
  top: 18px;
  bottom: 18px;
  margin-left: -24px;
  border-left: 1px solid var(--v-gray-20-base);
}

.grid-cols-3auto {
  grid-template-columns: 1fr auto 1fr;
}

.due-date-btn {
  max-height: 18px;
  padding: 4px !important;
  border-right: 3px !important;
}

.due-date-tag {
  max-height: 18px;
  border-radius: 5px;
  width: fit-content;
  padding: 2px 4px;
}
</style>
